import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import Header from './components/Header'
import Background from './components/Background';
import Home from './components/Home'

import Theme from './utils/theme';

import './App.css';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <Router>
          {/* <Header /> */}
          <Background />
          <Switch>
            <Route path="/" exact component={Home} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
