import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
// typography: {
//   fontFamily: ['Raleway'],
// },
palette: {
  primary: {
    light: '#3b3b3b',
    main: 'rgba(46, 46, 46, 0.8)',
    dark: '#1c1c1c',
    contrastText: '#ffffff',
  },
  // border: '#d5d5d5',
  secondary: {
    main: '#FFFFFF',
    dark: '#EEEEEE',
    contrastText: '#222222',
  },
},
spacing: 4,
// overrides: {
//   MuiButton: {
//     root: {
//       borderRadius: '20px',
//     },
//   },
// },
});
