import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Typography, IconButton, Tabs, Tab } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import PlaylistCard from './PlaylistCard';

const useStyles = makeStyles(theme => ({
  playlistsContainer: {
    height: '100vh',
    // paddingTop: '64px',
  },
  activeItem: {
    transition: '0.7s ease',
  },
  playlistsItems: {
    height: '100%',
    // paddingTop: '80px',
    position: 'relative',
    transition: '0.7s ease',
  },
  playlistContainer: {
    height: '100%',
    overflow: 'scroll',
    paddingBottom: '128px',
    transition: '0.7s ease',
  },
  hidePlaylists: {
    transform: 'translate(100%)',
  },
  playlistHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(4),
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  hideShowButton: {
    color: theme.palette.primary.contrastText,
  },
  playlistHeaderText: {
    marginLeft: theme.spacing(2),
    verticalAlign: 'middle',
  },
  fadeActivePlaylist: {
    opacity: 0,
    transform: 'translate(-15px)',
  },
  fadePlaylistItems: {
    opacity: 0,
    transform: 'translate(15px)',
  },
}));

const playlists = [
  {
    'name': 'Psychedelic Experiences',
    'playlistId': 'PLj73wp4Bp-iFS8zKX4aqXJoKoxl-EyqHm',
  },
  {
    'name': 'Mind Benders',
    'playlistId': 'PLj73wp4Bp-iGamn44xtREzeiZbZpBNyCE',
  },
  {
    'name': 'Timelapse',
    'playlistId': 'PLj73wp4Bp-iHE4YuEqsoHcwnwMPWfnbaZ',
  },
  {
    'name': 'Fractals & Zooms',
    'playlistId': 'PLj73wp4Bp-iE367FNp-F1zsxBWwCConwr',
  },
  {
    'name': 'First Person',
    'playlistId': 'PLj73wp4Bp-iFkMDvLER2NkpMrshAcKarU',
  },
  {
    'name': 'Flowers & Plants',
    'playlistId': 'PLj73wp4Bp-iEnUiuz00aZlTjnAacFzHVM',
  },
  {
    'name': 'Trippy Cartoons',
    'playlistId': 'PLj73wp4Bp-iFsPzGarMsI4BApmUbVzbbs',
  },
  {
    'name': 'Music Videos',
    'playlistId': 'PLj73wp4Bp-iFU-81C1NMaQ2OH2Ve6Smq9',
  },
]

export default function Playlists() {
  const classes = useStyles();

  const [fadeIn, setFadeIn] = React.useState(true);

  React.useEffect(() => {
    setTimeout(function() {
      setFadeIn(false);
    }, 1)
  }, []);

  const [activePlaylist, setActivePlaylist] = React.useState(0);
  const [showPlaylists, setShowPlaylists] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabs = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Grid container className={classes.playlistsContainer}>
      {/* <Grid item xs={showPlaylists ? 6 : 8} className={classes.activeItem} style={showPlaylists ? {} : {marginLeft: 'auto'}}> */}
      <Grid item xs={9} className={`${classes.activeItem} ` + (fadeIn ? classes.fadeActivePlaylist : '')}>
        <PlaylistCard name={playlists[activePlaylist].name} playlist={playlists[activePlaylist].playlistId} />
      </Grid>
      {/* <Grid item xs={showPlaylists ? 6 : 2} className={classes.playlistsItem} style={showPlaylists ? {} : {transform: 'translate(75%)', marginLeft: 'auto'}}> */}
      <Grid item xs={3} className={`${classes.playlistsItems} ` + (fadeIn ? classes.fadePlaylistItems : '')}>
        {/* <Paper className={classes.playlistHeader}>
          <IconButton className={classes.hideShowButton} onClick={() => setShowPlaylists(!showPlaylists)}>
            {showPlaylists ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
          </IconButton>
          <Typography variant="h5" component="span" className={classes.playlistHeaderText}>Playlists</Typography>
          <Tabs
            value={activeTab}
            onChange={handleTabs}
            // indicatorColor="primary"
            // textColor="primary"
          >
            <Tab label="Playlists" />
            <Tab label="Videos" />
          </Tabs>
        </Paper> */}
        <Grid container className={classes.playlistContainer} style={showPlaylists ? {} : {transform: 'translate(100%)'}}>
          {playlists.map((playlist, index) => {
            return <Grid item xs={12} key={index}>
              <PlaylistCard name={playlist.name} playlist={playlist.playlistId} onSetActive={() => setActivePlaylist(index)} />
            </Grid>
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}
