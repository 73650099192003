import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

import Welcome from './Welcome';
import Playlists from './Playlists';

const useStyles = makeStyles(theme => ({

}));

export default function Home() {
  const classes = useStyles();

  const [displayWelcome, setDisplayWelcome] = React.useState(true);

  return (
    <div>
      {displayWelcome ?
        <Welcome onStart={() => setDisplayWelcome(false)} />
        :
        <Playlists />
      }
    </div>
  )
}
