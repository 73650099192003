import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  welcomeText: {
    color: theme.palette.primary.contrastText,
    transition: '3s ease',
  },
  welcomeButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    fontSize: '48px',
    transition: '3s ease',
  },
  fadeIn: {
    opacity: '0',
    transform: 'translate(0, -15px)',
  },
  fadeOut: {
    opacity: '0',
    transform: 'translate(0, 15px)',
  },
}));

export default function Welcome({ onStart }) {
  const classes = useStyles();

  const [fadeText, setFadeText] = React.useState(true);
  const [fadeButton, setFadeButton] = React.useState(true);
  const [fadeOut, setFadeOut] = React.useState(false);

  React.useEffect(() => {
    setTimeout(function() {
      setFadeText(false);
    }, 500)
    setTimeout(function() {
      setFadeButton(false);
    }, 2000)
  }, []);

  function handleStart() {
    setFadeOut(true);
    setTimeout(function() {
      onStart();
    }, 1000)
  }

  return (
    <Box py={24}>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Typography
            variant="h1"
            className={`${classes.welcomeText} ` + (fadeText ? classes.fadeIn : '') + (fadeOut ? classes.fadeOut : '')}
          >
            Welcome to trippin.me
          </Typography>
        </Grid>
        <Grid item>
          <Box my={12}>
            <Button
              variant="contained"
              color="secondary"
              className={`${classes.welcomeButton} ` + (fadeButton ? classes.fadeIn : '') + (fadeOut ? classes.fadeOut : '')}
              onClick={() => handleStart()}
            >
              Get Trippy
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
