import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  canvas: {
    position: 'absolute',
    zIndex: '-1',
  },
}));

export default function Background() {
  const classes = useStyles();

  useEffect(() => {
    let canvas = document.querySelector('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let c = canvas.getContext('2d');

    window.addEventListener('resize', function() {
    	canvas.width = window.innerWidth;
    	canvas.height = window.innerHeight;

    	init();
    });

    function Dot(x, y, dx, dy, radius, color, offset) {
      this.x = x;
      this.y = y;
      this.dx = dx;
      this.dy = dy;
			this.radius = radius;
			// this.color = color;
      this.offset = offset;
      this.time = 0;

      this.update = function() {
        if (this.x + this.radius > canvas.width || this.x - this.radius < 0) {
    			this.dx = -this.dx;
    		}
    		if (this.y + this.radius > canvas.height || this.y - this.radius < 0) {
    			this.dy = -this.dy;
    		}

    		this.x += this.dx;
    		this.y += this.dy;

        this.time++;

        this.draw();
      }

      this.draw = function() {
        c.beginPath();
    		c.arc(this.x, this.y, this.radius, 0, Math.PI*2);
    		c.fillStyle = `rgba(255, 255, 255, ${Math.sin(this.time / 100 + this.offset)})`;
    		c.fill();
      }
    }

    function Planet(x, y, radius) {
      this.x = x;
      this.y = y;
      this.radius = radius;
      this.colorOffset = 0;
      this.colorVelocity = 0.3;
      this.colorArray = ['green', 'blue', 'red', 'purple', 'yellow', 'orange', 'pink', '#3083ff', '#ff3072', 'cyan', 'green']

      this.update = function() {
    		this.colorOffset += this.colorVelocity;
        if (this.colorOffset > 1000) {
          this.colorOffset = 0;
        }

        this.draw();
      }

      this.draw = function() {c.beginPath();
        c.arc(this.x, this.y, this.radius, 0, Math.PI*2);
        let gradient = c.createLinearGradient(this.x - 950 + this.colorOffset,
                                              this.y - 950 + this.colorOffset,
                                              this.x - 50 + this.colorOffset,
                                              this.y - 50 + this.colorOffset);
        let stop = 0;
        for (let color of this.colorArray) {
          gradient.addColorStop(stop, color);
          stop += 0.1;
        }
        c.fillStyle = gradient;
        c.fill();

        c.globalCompositeOperation='source-atop';

        c.arc(this.x, this.y, this.radius, 0, Math.PI*2);
        let gradientShadow = c.createRadialGradient(this.x - 20, this.y - 20, this.radius,
                                                    this.x - 20, this.y - 20, this.radius * 1.4);
        gradientShadow.addColorStop(0, 'transparent');
        gradientShadow.addColorStop(1, 'black');
        c.fillStyle = gradientShadow;
        c.fill();

        c.globalCompositeOperation='source-over';
      }
    }

    function Moon(x, y, radius, color) {
      this.x = x;
      this.y = y;
      this.radius = radius;
      this.color = color;
    	this.radians = Math.PI * 2;
    	this.velocity = 0.005;

      this.update = function() {
    		// Move over time
    		this.radians += this.velocity;

    		// Circular motion
        let diffY = Math.sin(-this.radians);
    		this.x = this.x + Math.cos(-this.radians + 1);
    		this.y = this.y + diffY * 0.5;

        this.draw(Boolean(diffY >= 0));
      }

      this.draw = function(under) {
        c.globalCompositeOperation = (under ? 'destination-over' : 'source-over');
        c.beginPath();
        c.arc(this.x, this.y, this.radius, 0, Math.PI*2);
        c.fillStyle = this.color;
        c.fill();

        c.globalCompositeOperation='source-atop';

        c.arc(this.x, this.y, this.radius, 0, Math.PI*2);
        let gradientShadow = c.createRadialGradient(this.x - 5, this.y - 5, this.radius,
                                                    this.x - 5, this.y - 5, this.radius * 2.5);
        gradientShadow.addColorStop(0, 'transparent');
        gradientShadow.addColorStop(1, 'black');
        c.fillStyle = gradientShadow;
        c.fill();

        c.globalCompositeOperation='source-over';
      }
    }

    let dotArray = [];
    let planet;
    let moon;

    function init() {
			createDots();
      planet = new Planet(canvas.width / 2, canvas.height / 1.5, 100);
      moon = new Moon(canvas.width / 2 - 150, canvas.height / 1.5 + 100, 10, '#bbbbbb');
		}

    function createDots() {
      dotArray = [];

    	for (var i = 0; i < 500; i++) {
        var radius = Math.random() * 3 + 1;
    		var x = Math.random() * (canvas.width - radius * 2) + radius;
    		var y = Math.random() * (canvas.height - radius * 2) + radius;
    		var dx = (Math.random() - 0.5) * 0.2;
    		var dy = (Math.random() - 0.5) * 0.2;
        var offset = Math.random() * 6;
    		dotArray.push(new Dot(x, y, dx, dy, radius, 'white', offset));
    	}
    }

    let fadeIn = 1;

    function animate() {
			requestAnimationFrame(animate);

      c.clearRect(0, 0, canvas.width, canvas.height);
      // c.fillStyle = 'rgba(28, 28, 28, 0.05)';
			// c.fillRect(0, 0, canvas.width, canvas.height);

      for (let dot of dotArray) {
    		dot.update();
    	}
      planet.update();
      moon.update();

      c.fillStyle = `rgba(28, 28, 28, ${fadeIn})`;
			c.fillRect(0, 0, canvas.width, canvas.height);
      fadeIn -= 0.005;
		}

		init();
		animate();
  }, [])

  return (
    <canvas className={classes.canvas} />
  )
}
