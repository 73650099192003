import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardHeader, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  playlistCard: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
  playlistContent: {
    padding: 0,
  },
  playlistWrapper: {
    paddingTop: '56.25%',
    position: 'relative',
    width: '100%',
  },
  playlistFrame: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
  },
}));

export default function PlaylistCard({ name, playlist, onSetActive }) {
  const classes = useStyles();

  return (
    <Box p={4}>
      <Card className={classes.playlistCard} onClick={() => onSetActive()}>
        <CardHeader title={name} />
        <CardContent className={classes.playlistContent}>
          <div className={classes.playlistWrapper}>
            <iframe
              className={classes.playlistFrame}
              src={"https://www.youtube.com/embed/videoseries?list=" + playlist}
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen="0"
              title="youtube-playlist">
            </iframe>
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}
